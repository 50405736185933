
import { defineComponent, ref, onMounted, getCurrentInstance, watch } from "vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import Validation from "@/components/Validation.vue";
import useVuelidate from "@vuelidate/core";
import FscDatepicker from "@/components/FscDatepicker.vue";
import { required } from "@vuelidate/validators";
import moment from "moment";
import axios from "@/utils/axios";
import { getBrowser, getScreenSize } from "@/helper/UserAgentData";
import i18n from "@/i18n";
import { Capacitor } from "@capacitor/core";
import { getBuildInfo } from "vue-cli-plugin-build-info/plugin";
import UserService from "@/services/UserService";
import { Device } from "@capacitor/device";
import { deviceMap } from "@/utils/deviceMap";
import get from "lodash/get";

export default defineComponent({
  name: "TechnicalProblemView",
  components: { DefaultLayout, Validation, FscDatepicker },
  setup: function () {
    const description = ref<string>("");
    const dateTime = ref<string | null>(null);
    const file = ref<File | null>(null);
    const fileRef: any | null = ref<HTMLInputElement | null>(null);
    const $toasted: any | null = getCurrentInstance()?.appContext.config.globalProperties.$toasted;
    const loading = ref(false);
    const studentData = ref<string | null>(null);
    const gdprConfirmed = ref<boolean | null>(null);
    const fileUrl = ref<string>("");
    watch(
      () => [description.value, dateTime.value],
      async (val: any) => {
        const { browser, browserVersion } = getBrowser();

        const { lastName, firstName, drivingSchoolName, email } = UserService.getUser();

        const info = await Device.getInfo();

        let appType = "drive.buzz web";

        if (Capacitor.isNativePlatform()) {
          appType = "drive.buzz";
        }

        const deviceName = ("ios" === info.platform ? get(deviceMap, info.model) : info?.name) || "";

        const manufacturer = `${info.manufacturer} ${deviceName}`;

        studentData.value = `Name: ${lastName} ${firstName}
Mail: ${email}
Fahrschule: ${drivingSchoolName}
Datum: ${val[1]?.split(" ")[0]}
Uhrzeit: ${val[1]?.split(" ")[1]}
Beschreibung: ${val[0]}
App: ${appType}
Hersteller: ${manufacturer}
OSVersion: ${info.operatingSystem} ${info.osVersion}
Bildschirmgröße: ${getScreenSize()}
Browser: ${[browser, browserVersion].join(" ")}`;
      },
      { immediate: true }
    );

    const v$ = useVuelidate(
      {
        description: { required },
        dateTime: { required },
        gdprConfirmed: { required: (v: boolean) => Boolean(v) },
      },
      { description, dateTime, gdprConfirmed }
    );

    onMounted(() => {
      dateTime.value = moment().format("YYYY-MM-DD HH:mm");
    });
    const onFileChange = () => {
      if (fileRef?.value as HTMLInputElement) {
        file.value = fileRef?.value?.files[0];
        fileUrl.value = URL.createObjectURL(file.value);
      }
    };
    const onDiscardChange = () => {
      file.value = null;
      fileRef.value.files = null;
    };
    const onSubmit = async () => {
      v$.value.$touch();
      if (v$.value.$invalid) return;
      loading.value = true;

      const { browser, browserVersion } = getBrowser();
      const formData = new FormData();
      formData.append("file", file.value as File);
      const { VERSION } = getBuildInfo();

      let appVersion = `web: ${VERSION}`;
      if (Capacitor.isNativePlatform()) {
        appVersion = `native: ${process.env.VUE_APP_VERSION}`;
      }

      const info = await Device.getInfo();

      let appType = "drive.buzz web";

      if (Capacitor.isNativePlatform()) {
        appType = "drive.buzz";
      }

      const deviceName = ("ios" === info.platform ? get(deviceMap, info.model) : info?.name) || "";

      let manufacturer = `${info.manufacturer} ${deviceName}`;

      const data: any = {
        dateTime: moment(dateTime.value).format("YYYY-MM-DDTHH:mm:ss"),
        description: description.value as string,
        browser: [browser, browserVersion].join(" "),
        osandVersion: `${info.operatingSystem} ${info.osVersion}`,
        screenSize: getScreenSize(),
        manufacturer: manufacturer,
        appVersion: appVersion,
        userData: studentData.value,
        app: appType,
      };

      formData.append("bugReportDTO", JSON.stringify(data));

      await axios
        .post("bug-report/create", formData, { headers: { "Content-Type": "multipart/form-data" } })
        .then((res: any) => {
          //@ts-ignore
          $toasted?.success(i18n.global.t("messages.report_sent").toString(), { duration: 3000 });
          description.value = "";
          fileUrl.value = "";
          file.value = null;
          onDiscardChange();
          v$.value.$reset();
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const pickFile = () => {
      fileRef.value.click();
    };

    return {
      description,
      dateTime,
      file,
      v$,
      onSubmit,
      onFileChange,
      onDiscardChange,
      fileRef,
      loading,
      gdprConfirmed,
      studentData,
      pickFile,
      fileUrl,
    };
  },
});
